var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Kendaraan"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(tgl_stnk)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tgl_stnk)) + " ")];
      }
    }, {
      key: "cell(tgl_kir)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tgl_kir)) + " ")];
      }
    }, {
      key: "cell(tgl_pajak_th)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tgl_pajak_th)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Riwayat',
            expression: "'Riwayat'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/ceklist/riwayat/".concat(row.item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CheckCircleIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-ubah",
      "cancel-variant": "secondary",
      "ok-title": "Ubah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Ubah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Ubah ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Hapus isian ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalEdit = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalEdit,
      callback: function callback($$v) {
        _vm.showModalEdit = $$v;
      },
      expression: "showModalEdit"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v("Nama Kendaraan")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Nama Kendaraan"
    },
    model: {
      value: _vm.form.nama_kendaraan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nama_kendaraan", $$v);
      },
      expression: "form.nama_kendaraan"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "merk"
    }
  }, [_vm._v("Merk Kendaraan")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Merk Kendaraan"
    },
    model: {
      value: _vm.form.merk_kendaraan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "merk_kendaraan", $$v);
      },
      expression: "form.merk_kendaraan"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "jenis"
    }
  }, [_vm._v("Jenis Kendaraan")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Jenis Kendaraan"
    },
    model: {
      value: _vm.form.jenis_kendaraan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "jenis_kendaraan", $$v);
      },
      expression: "form.jenis_kendaraan"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "plat_nomer"
    }
  }, [_vm._v("Plat Nomor Kendaraan")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Plat Nomor Kendaraan"
    },
    model: {
      value: _vm.form.plat_nomer,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "plat_nomer", $$v);
      },
      expression: "form.plat_nomer"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v("Nomor STNK")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Nomor STNK"
    },
    model: {
      value: _vm.form.no_stnk,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "no_stnk", $$v);
      },
      expression: "form.no_stnk"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v("Tanggal STNK")]), _c('b-form-input', {
    attrs: {
      "type": "date",
      "placeholder": "Tanggal Pepanjangan STNK"
    },
    model: {
      value: _vm.form.tgl_stnk,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_stnk", $$v);
      },
      expression: "form.tgl_stnk"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v("Nomor KIR")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Nomor STNK"
    },
    model: {
      value: _vm.form.no_kir,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "no_kir", $$v);
      },
      expression: "form.no_kir"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v("Tanggal Perpanjangan KIR")]), _c('b-form-input', {
    attrs: {
      "type": "date",
      "placeholder": "Tanggal Pepanjangan STNK"
    },
    model: {
      value: _vm.form.tgl_kir,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_kir", $$v);
      },
      expression: "form.tgl_kir"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v("Tanggal Perpanjangan Pajak Tahunan ")]), _c('b-form-input', {
    attrs: {
      "type": "date",
      "placeholder": "Tanggal Pepanjangan STNK"
    },
    model: {
      value: _vm.form.tgl_pajak_th,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tgl_pajak_th", $$v);
      },
      expression: "form.tgl_pajak_th"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }